import { Routes } from '@angular/router';

export const appRoutes: Routes = [
  {
    path: '',
    loadChildren: () =>
      import('./views/root/homepage/system-method-homepage.module').then(
        (mod) => mod.SystemMethodHomepageModule
      ),
  },
  {
    path: 'solutions',
    loadChildren: () =>
      import('./views/root/solutions/solutions.module').then(
        (mod) => mod.SolutionsModule
      ),
  },
  {
    path: 'icebreak',
    loadChildren: () =>
      import('./views/root/icebreak/icebreak.module').then(
        (mod) => mod.SystemMethodIcebreakModule
      ),
  },
  {
    path: 'icecap',
    loadChildren: () =>
      import('./views/root/icecap/icecap.module').then(
        (mod) => mod.SystemMethodIcecapModule
      ),
  },
  {
    path: 'blueseries',
    loadChildren: () =>
      import('./views/root/blueseries/blueseries.module').then(
        (mod) => mod.BlueseriesModule
      ),
  },
  {
    path: 'bluenote',
    loadChildren: () =>
      import('./views/root/bluenote/bluenote.module').then(
        (mod) => mod.BluenoteModule
      ),
  },
  {
    path: 'interform',
    loadChildren: () =>
      import('./views/root/interform/interform.module').then(
        (mod) => mod.InterformModule
      ),
  },
  {
    path: 'zipseries',
    loadChildren: () =>
      import('./views/root/zipseries/zipseries.module').then(
        (mod) => mod.ZipseriesModule
      ),
  },
  {
    path: 'hosting',
    loadChildren: () =>
      import('./views/root/hosting/hosting.module').then(
        (mod) => mod.HostingModule
      ),
  },
  {
    path: 'padform',
    loadChildren: () =>
      import('./views/root/padform/padform.module').then(
        (mod) => mod.PadformModule
      ),
  },
  {
    path: 'appstore',
    loadChildren: () =>
      import('./views/root/appstore/appstore.module').then(
        (mod) => mod.AppstoreModule
      ),
  },
  {
    path: 'icebreak-server-edition',
    loadChildren: () =>
      import('./views/root/server-edition/server-edition.module').then(
        (mod) => mod.ServerEditionModule
      ),
  },
  {
    path: 'web-application-development-made-easy',
    loadChildren: () =>
      import('./views/root/easy-to-learn/easy-to-learn.module').then(
        (mod) => mod.EasyToLearnModule
      ),
  },
  {
    path: 'icecap-overview',
    loadChildren: () =>
      import('./views/root/icecap-overview/icecap-overview.module').then(
        (mod) => mod.IcecapOverviewModule
      ),
  },
  {
    path: 'icecap-efficiency',
    loadChildren: () =>
      import('./views/root/icecap-efficiency/icecap-efficiency.module').then(
        (mod) => mod.IcecapEfficiencyModule
      ),
  },
  {
    path: 'icecap-architecture',
    loadChildren: () =>
      import(
        './views/root/icecap-architecture/icecap-architecture.module'
      ).then((mod) => mod.IcecapArchitectureModule),
  },
  {
    path: 'icecap-functionality',
    loadChildren: () =>
      import(
        './views/root/icecap-functionality/icecap-functionality.module'
      ).then((mod) => mod.IcecapFunctionalityModule),
  },
  {
    path: 'icebreak-community-edition',
    loadChildren: () =>
      import('./views/root/community-edition/community-edition.module').then(
        (mod) => mod.CommunityEditionModule
      ),
  },
  {
    path: 'icebreak-enterprise-edition',
    loadChildren: () =>
      import('./views/root/enterprise-edition/enterprise-edition.module').then(
        (mod) => mod.EnterpriseEditionModule
      ),
  },
  {
    path: 'icebreak-features',
    loadChildren: () =>
      import('./views/root/compare-features/compare-features.module').then(
        (mod) => mod.CompareFeaturesModule
      ),
  },
  {
    path: 'blueseries-fax',
    loadChildren: () =>
      import('./views/root/blueseries-fax/blueseries-fax.module').then(
        (mod) => mod.BlueseriesFaxModule
      ),
  },
  {
    path: 'blueseries-mail',
    loadChildren: () =>
      import('./views/root/blueseries-mail/blueseries-mail.module').then(
        (mod) => mod.BlueseriesMailModule
      ),
  },
  {
    path: 'blueseries-xml',
    loadChildren: () =>
      import('./views/root/blueseries-xml/blueseries-xml.module').then(
        (mod) => mod.BlueseriesXmlModule
      ),
  },
  {
    path: 'blueseries-web-client',
    loadChildren: () =>
      import(
        './views/root/blueseries-web-client/blueseries-web-client.module'
      ).then((mod) => mod.BlueseriesWebClientModule),
  },
  {
    path: 'blueseries-nemhandel',
    loadChildren: () =>
      import(
        './views/root/blueseries-nemhandel/blueseries-nemhandel.module'
      ).then((mod) => mod.BlueseriesNemhandelModule),
  },
  {
    path: 'blueseries-hardware',
    loadChildren: () =>
      import(
        './views/root/blueseries-hardware/blueseries-hardware.module'
      ).then((mod) => mod.BlueseriesHardwareModule),
  },
  {
    path: 'bluenote-hardware',
    loadChildren: () =>
      import('./views/root/bluenote-hardware/bluenote-hardware.module').then(
        (mod) => mod.BluenoteHardwareModule
      ),
  },
  {
    path: 'bluenote-communicator',
    loadChildren: () =>
      import(
        './views/root/bluenote-communicator/bluenote-communicator.module'
      ).then((mod) => mod.BluenoteCommunicatorModule),
  },
  {
    path: 'bluenote-monitor',
    loadChildren: () =>
      import('./views/root/bluenote-monitor/bluenote-monitor.module').then(
        (mod) => mod.BluenoteMonitorModule
      ),
  },
  {
    path: 'zipseries-editions',
    loadChildren: () =>
      import('./views/root/zipseries-editions/zipseries-editions.module').then(
        (mod) => mod.ZipseriesEditionsModule
      ),
  },
  {
    path: 'zipseries-zipper',
    loadChildren: () =>
      import(
        './views/root/zipseries-zip-unzip/zipseries-zip-unzip.module'
      ).then((mod) => mod.ZipseriesZipUnzipModule),
  },
  {
    path: 'padform-overview',
    loadChildren: () =>
      import('./views/root/padform-overview/padform-overview.module').then(
        (mod) => mod.PadformOverviewModule
      ),
  },
  {
    path: 'padform-features',
    loadChildren: () =>
      import('./views/root/padform-features/padform-features.module').then(
        (mod) => mod.PadformFeaturesModule
      ),
  },
  {
    path: 'padform-integration',
    loadChildren: () =>
      import(
        './views/root/padform-integration/padform-integration.module'
      ).then((mod) => mod.PadformIntegrationModule),
  },
  {
    path: 'padform-campaign',
    loadChildren: () =>
      import('./views/root/padform-campaign/padform-campaign.module').then(
        (mod) => mod.PadformCampaignModule
      ),
  },
  {
    path: 'padform-ege',
    loadChildren: () =>
      import('./views/root/padform-salesapp/padform-salesapp.module').then(
        (mod) => mod.PadformSalesappModule
      ),
  },
  {
    path: '101',
    loadChildren: () =>
      import('./views/root/padform-guide/padform-guide.module').then(
        (mod) => mod.PadformGuideModule
      ),
  },
  {
    path: 'hosting',
    loadChildren: () =>
      import('./views/root/hosting/hosting.module').then(
        (mod) => mod.HostingModule
      ),
  },
  {
    path: 'hosting-costs',
    loadChildren: () =>
      import('./views/root/hosting-costs/hosting-costs.module').then(
        (mod) => mod.HostingCostsModule
      ),
  },
  {
    path: 'hosting-security',
    loadChildren: () =>
      import('./views/root/hosting-security/hosting-security.module').then(
        (mod) => mod.HostingSecurityModule
      ),
  },
  {
    path: 'interform-overview',
    loadChildren: () =>
      import('./views/root/interform-overview/interform-overview.module').then(
        (mod) => mod.InterformOverviewModule
      ),
  },
  {
    path: 'interform-pdf',
    loadChildren: () =>
      import('./views/root/interform-pdf/interform-pdf.module').then(
        (mod) => mod.InterformPdfModule
      ),
  },
  {
    path: 'interform-trt',
    loadChildren: () =>
      import('./views/root/interform-trt/interform-trt.module').then(
        (mod) => mod.InterformTrtModule
      ),
  },
  {
    path: 'interform-volvo',
    loadChildren: () =>
      import('./views/root/interform-volvo/interform-volvo.module').then(
        (mod) => mod.InterformVolvoModule
      ),
  },
  {
    path: 'interform-security',
    loadChildren: () =>
      import('./views/root/interform-security/interform-security.module').then(
        (mod) => mod.InterformSecurityModule
      ),
  },
  {
    path: 'interform-labels',
    loadChildren: () =>
      import('./views/root/interform-labels/interform-labels.module').then(
        (mod) => mod.InterformLabelsModule
      ),
  },
  {
    path: 'interform-interword',
    loadChildren: () =>
      import(
        './views/root/interform-interword/interform-interword.module'
      ).then((mod) => mod.InterformInterwordModule),
  },
  {
    path: 'interform-interexcel',
    loadChildren: () =>
      import(
        './views/root/interform-interexcel/interform-interexcel.module'
      ).then((mod) => mod.InterformInterexcelModule),
  },
  {
    path: 'interform-winprint',
    loadChildren: () =>
      import('./views/root/interform-winprint/interform-winprint.module').then(
        (mod) => mod.InterformWinprintModule
      ),
  },
  {
    path: 'appstore-icequery',
    loadChildren: () =>
      import('./views/root/appstore-icequery/appstore-icequery.module').then(
        (mod) => mod.AppstoreIcequeryModule
      ),
  },
  {
    path: 'license',
    loadChildren: () =>
      import('./views/root/license/license.module').then(
        (mod) => mod.LicenseViewModule
      ),
  },
  {
    path: 'appstore-fileshare',
    loadChildren: () =>
      import('./views/root/appstore-fileshare/appstore-fileshare.module').then(
        (mod) => mod.AppstoreFileshareModule
      ),
  },
  {
    path: 'appstore-workflowserver',
    loadChildren: () =>
      import('./views/root/appstore-workflow/appstore-workflow.module').then(
        (mod) => mod.AppstoreWorkflowModule
      ),
  },
  {
    path: 'appstore-multiarchive',
    loadChildren: () =>
      import(
        './views/root/appstore-multiarchive/appstore-multiarchive.module'
      ).then((mod) => mod.AppstoreMultiarchiveModule),
  },
  {
    path: 'appstore-cloudbackup',
    loadChildren: () =>
      import(
        './views/root/appstore-cloudbackup/appstore-cloudbackup.module'
      ).then((mod) => mod.AppstoreCloudbackupModule),
  },
  {
    path: 'appstore-webprinting',
    loadChildren: () =>
      import(
        './views/root/appstore-webprinting/appstore-webprinting.module'
      ).then((mod) => mod.AppstoreWebprintingModule),
  },
  {
    path: 'icebreak-pricelist',
    loadChildren: () =>
      import('./views/root/icebreak-pricelist/icebreak-pricelist.module').then(
        (mod) => mod.IcebreakPricelistModule
      ),
  },
  {
    path: 'contact',
    loadChildren: () =>
      import('./views/root/contact-team/contact-team.module').then(
        (mod) => mod.ContactTeamModule
      ),
  },
  {
    path: 'page/support',
    loadChildren: () =>
      import('./views/root/page-support/page-support.module').then(
        (mod) => mod.PageSupportModule
      ),
  },
  {
    path: 'support-maintenance',
    loadChildren: () =>
      import(
        './views/root/support-maintenance/support-maintenance.module'
      ).then((mod) => mod.SupportMaintenanceModule),
  },
  {
    path: 'all-customers',
    loadChildren: () =>
      import('./views/root/all-customers/all-customers.module').then(
        (mod) => mod.AllCustomersModule
      ),
  },
  {
    path: 'download',
    loadChildren: () =>
      import('./views/root/download/download.module').then(
        (mod) => mod.DownloadModule
      ),
  },
  {
    path: 'sitemule-model-studio-blog',
    loadChildren: () =>
      import(
        './views/root/blogs/sitemule-model-studio/sitemule-model-studio-blog.module'
      ).then((m) => m.SitemuleModelStudioBlogModule),
  },
  {
    path: 'icecap-portfolio-blog',
    loadChildren: () =>
      import(
        './views/root/blogs/icecap-portfolio/icecap-portfolio-blog.module'
      ).then((m) => m.IcecapPortfolioBlogModule),
  },
  {
    path: '**',
    loadChildren: () =>
      import('./views/404/404.module').then((mod) => mod.NotFoundModule),
  },
];
